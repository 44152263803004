import {createRouter, createWebHashHistory} from 'vue-router'
import Login from "@/views/Login.vue";
import Data from "@/views/Data.vue";


const router = createRouter({
  history: createWebHashHistory("./"),
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        needsAuth: false,
      },
    },
    {
      path: "/data",
      name: "Data",
      component: Data,
      meta: {
        needsAuth: true,
      },
    },
  ],
});

router.beforeEach((to,from,next) => {
  if (to.meta.needsAuth === true){
    const token = localStorage.getItem('token')

    //if not logged in, redirect to Login page
    if (!token) next ('/');

    //else continue
    else if (token === "Yr2ZOeGEh1Vs6tXqHUwF2i0yCy5WDtHd") {
      next();
    }
  } else {
    next();
  }
})

export default router
