<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 db-header">
        <div class="db-header-content h-100 d-flex align-items-center justify-content-center">
          <div class="db-header-title d-flex justify-content-center">
            <img class="d-inline" src="../assets/img_logo_1.png">
            <h2 class="d-inline d-none d-md-block m-auto">Dashboard Parelroutes Tholen</h2>
          </div>
          <button v-if="loggedIn === true" v-on:click="logout" to="/" tag="a" class="db-logout-btn me-3 me-sm-5 d-none d-md-block">Uitloggen</button>
          <button v-if="loggedIn === true" v-on:click="logout" to="/" tag="a" class="db-logout-btn me-4 me-sm-5 d-block d-md-none"><i class="fa fa-sign-out" aria-hidden="true"></i>
          </button>
<!--          <router-link v-if="loggedIn === true" v-on:click="logout" to="/" class="db-logout-btn me-5" tag="button">Uitloggen</router-link>-->
<!--          &lt;!&ndash;          <router-link class="db-logout-btn me-5" to="/"><button  role="link">Uitloggen</button></router-link>&ndash;&gt;-->
        </div>
      </div>
      <div class="col-12 db-changedate">
        <div class="db-changedate-content h-100 d-flex align-items-center justify-content-end">
          <p class="d-inline">Getoond jaar</p>
          <select class="d-inline" v-model="selectedYear">
            <option v-for="item in yearList" :value="item">{{item}}</option>
          </select>
        </div>
        <h2 class="d-flex justify-content-center">STATISTIEKEN {{ selectedYear }}</h2>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-md-6 col-lg-4 col-12 mt-5 mt-md-0 mb-3 mb-md-0">
        <div class="db-year-totaluse">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <img class="db-stats-title-img" src="../assets/img_title_totaal_gebruik_1.png">
            </div>

            <div class="col-12 d-flex justify-content-center">
              <h2 v-if="totalYear !== null" class="db-totalCount-text">{{totalYear}}<span>x</span></h2>
<!--              <p> {{ regionPieChartData.datasets[0].data}}</p>-->
<!--&lt;!&ndash;              <p> {{ regionPieChartData.key }}</p>&ndash;&gt;-->
<!--              <p> {{ totalMonthBarData.datasets[0].data }}</p>-->
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-12 mt-2 mt-md-0 mb-3 mb-md-0">
        <div class="db-year-totaluse">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <img class="db-stats-title-img" src="../assets/img_title_gelopen_routes_1.png">
            </div>

            <div class="col-12 d-flex justify-content-center">
              <h2 v-if="totalRoute !== null" class="db-totalCount-text">{{totalRoute}}<span>x</span></h2>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4 col-12 mt-3 mt-sm-5 mt-lg-0">
        <div class="db-year-totaluse d-flex justify-content-center">
          <div class="row">
            <div class="col-12 d-flex justify-content-center mb-3">
              <img class="db-stats-title-img" src="../assets/img_title_route_populariteit_1.png">
            </div>

            <div class="col-12 d-flex justify-content-center">
              <Pie class="db-pie-chart pe-0" v-if="regionPieChartData.datasets[0].data.length > 0" :key="regionPieChartData.key" :data="regionPieChartData" :options="regionPieChartOptions" />
              <p class="mt-2" v-if="regionPieChartData.datasets[0].data.length === 0">Geen data gevonden.</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-5">
      <div class="col-12 d-flex justify-content-center">
        <div class="row">
          <div class="col-12 d-flex justify-content-center mb-3">
            <img class="db-stats-title-img" src="../assets/img_title_gebruik_per_maand_1.png">
          </div>
<!--          <p> {{ totalMonthBarData.datasets[0].data }}</p>-->
<!--          <p> {{ totalMonthBarData.key }}</p>-->
          <div class="col-12 d-flex justify-content-center">
            <Bar class="db-bar" v-if="totalMonthBarData.datasets[0].data.length > 0" :data="totalMonthBarData" :key="totalMonthBarData.key" :options="totalMonthBarOptions" />
            <p class="mt-2" v-if="totalMonthBarData.datasets[0].data.length === 0">Geen data gevonden.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 d-flex justify-content-center">
        <div class="row">
          <div class="col-12 d-flex justify-content-center mb-3">
            <img class="db-stats-title-img" src="../assets/img_title_routes_per_maand_1.png">
          </div>
          <!--          <p> {{ totalMonthBarData.datasets[0].data }}</p>-->
          <!--          <p> {{ totalMonthBarData.key }}</p>-->
          <div class="col-12 d-flex justify-content-center">
            <Bar class="db-bar db-bar-bottom" v-if="totalRouteBarData.datasets[0].data.length > 0" :data="totalRouteBarData" :key="totalRouteBarData.key" :options="totalRouteBarOptions" />
            <p class="mt-2" v-if="totalRouteBarData.datasets[0].data.length === 0">Geen data gevonden.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 db-footer d-flex justify-content-center">
        <div class="db-footer-content d-flex justify-content-center align-items-center">
            <p class="mt-2 mt-lg-0 mb-2 mb-lg-0">Data welke ten grondslag ligt aan de getoonde statistieken wordt volledig geanomiseerd verkregen en valt niet te herleiden naar individuele gebruikers.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import { Pie } from 'vue-chartjs'
import { Bar } from 'vue-chartjs'

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: "Data",
  components: { Pie, Bar },

  data() {
    return {
      loggedIn: false,
      yearList: [],
      selectedYear: new Date().getFullYear(),
      totalYear: null,
      totalRoute: 0,
      YearPieChartByRoutePop: false,
      totalYearPopByRoute: [],
      routes: [
        {routenumber: '1'},
        {routenumber: '2'},
        {routenumber: '3'},
      ],
      months: [
        {month: '01'},
        {month: '02'},
        {month: '03'},
        {month: '04'},
        {month: '05'},
        {month: '06'},
        {month: '07'},
        {month: '08'},
        {month: '09'},
        {month: '10'},
        {month: '11'},
        {month: '12'},
      ],

      totalMonthBarData: {
        key: 0,
        labels: [
          'Januari',
          'Februari',
          'Maart',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Augustus',
          'September',
          'Oktober',
          'November',
          'December'
        ],
        datasets: [
          {
            label: "Gebruik",
            backgroundColor: '#f8ad2c',
            data: []
          }
        ]
      },
      totalMonthBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },

      totalRouteBarData: {
        key: 0,
        labels: [
          'Januari',
          'Februari',
          'Maart',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Augustus',
          'September',
          'Oktober',
          'November',
          'December'
        ],
        datasets: [
          {
            label: "Tholen",
            backgroundColor: '#f8ad2c',
            data: [10,12,13,10,15,12,12,12,12,12,12,5]
          },
          {
            label: "Scherpenisse",
            backgroundColor: '#63b746',
            data: [10,12,13,10,15,12,12,12,12,12,12,5]
          },
          {
            label: "Sint-Phillipsland",
            backgroundColor: '#1c9ad6',
            data: [10,12,13,10,15,12,12,12,12,12,12,5]
          }
        ]
      },
      totalRouteBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      regionPieChartData: {
        key: 0,
        labels: ['Tholen', 'Scherpenisse', 'Sint-Philipsland'],
        datasets: [
          {
            backgroundColor: ['#f8ad2c', '#63b746', '#1c9ad6'],
            data: [],
          }
        ]
      },
      regionPieChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };

  },

  created() {
    this.getYears();
    this.getYearTotal();
    this.getRoutesTotal();
    this.getPopularityByRoute();
    this.getTotalByMonth();
    this.getRoutesPerMonth();
  },


  mounted() {
    if(localStorage.getItem("token") === "Yr2ZOeGEh1Vs6tXqHUwF2i0yCy5WDtHd"){
      this.loggedIn = true;
    }
  },

  watch: {
    selectedYear: function(value) {
      this.getYearTotal();
      this.getRoutesTotal();
      this.getPopularityByRoute();
      this.getTotalByMonth();
      this.getRoutesPerMonth();
    }
  },

  methods: {

    async getYears() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/controller/yearController.php", requestOptions);
      const data = await response.json();
      if (data['success'] === true && data['response'].length > 0) {
        for (let i = 0; i < data['response'].length; i++) {
          this.yearList.push((data['response'][i]['Year']));
        }
      }
    },

    async getYearTotal() {
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(this.selectedYear)
      };
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/data/getYearTotal.php", requestOptions);
      const data = await response.json();
      this.totalYear = data['response'][0]['COUNT(vis_datetimestamp)']
    },

    async getPopularityByRoute() {
      let zeroCount = 0;
      for (let i = 0; i < this.routes.length; i++) {

        let popArr =
          {
            year: this.selectedYear,
            routetype: this.routes[i].routenumber
          }
          const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(popArr)
        };

        const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/data/getRoutePopularityYear.php", requestOptions);
        const data = await response.json();

        if (data['success'] === true && data['response'] !== false) {
          if (parseInt(data['response'][0]['COUNT(rov_id)']) === 0) {
            zeroCount++;
          }
          this.regionPieChartData.datasets[0].data[i] = (parseInt(data['response'][0]['COUNT(rov_id)']))
        }
      }

      if (zeroCount === 3) {
        this.regionPieChartData.datasets[0].data = []
        this.regionPieChartData.key = 3;
      } else {
        this.regionPieChartData.key++;
      }
    },

    async getTotalByMonth() {

      let zeroCount = 0;
      for (let i = 0; i < this.months.length; i++) {
        let totalMonthArr =
            {
              month: this.months[i].month,
              year: this.selectedYear,
            }
        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(totalMonthArr)
        };

        const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/data/getTotalByMonth.php", requestOptions);
        const data = await response.json();

        if (data['success'] === true && data['response'] !== false) {
          if (parseInt(data['response'][0]['COUNT(vis_datetimestamp)']) === 0) {
            zeroCount++;
          }
          this.totalMonthBarData.datasets[0].data[i] = (parseInt(data['response'][0]['COUNT(vis_datetimestamp)']))
        }
      }

      if (zeroCount === 12) {
        this.totalMonthBarData.datasets[0].data = []
        this.totalMonthBarData.key = 3;
      } else {
        this.totalMonthBarData.key++;
      }
    },

    async getRoutesPerMonth() {

      let zeroCount = 0;

      for (let r = 0; r < this.routes.length; r++) {
        for (let m = 0; m < this.months.length; m++) {
          // console.log(this.months[m].month, this.routes[r].routenumber)

          let totalRouteArr =
          {
            month: this.months[m].month,
            year: this.selectedYear,
            type: this.routes[r].routenumber,
          }

          const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(totalRouteArr)
          };

          const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/data/getRouteByMonth.php", requestOptions);
          const data = await response.json();

          if (data['success'] === true && data['response'] !== false) {
            if (parseInt(data['response'][0]['COUNT(rov_datetimestamp)']) === 0) {
              zeroCount++;
            }
            this.totalRouteBarData.datasets[r].data[m] = (parseInt(data['response'][0]['COUNT(rov_datetimestamp)']))
          }
          // console.log(this.totalRouteBarData.datasets[r].data[m]);

        }
      }
      if (zeroCount === 36) {
        this.totalRouteBarData.datasets[0].data = []
        this.totalRouteBarData.key = 3;
      } else {
        this.totalRouteBarData.key++;
      }
    },


    async getRoutesTotal() {

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(this.selectedYear)
      };

      const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/data/getRoutesTotal.php", requestOptions);
      const data = await response.json();

      this.totalRoute = (data['response'][0]['COUNT(rov_datetimestamp)'])

    },

    logout() {
      localStorage.removeItem('token')
      this.$router.push("/");
    },

  },
}

</script>
