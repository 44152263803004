<template>
  <router-view></router-view>
</template>


<style>
@import "../src/assets/main.css";
</style>

<script>
export default {
  data() {
  },

  watch: {
    '$route': 'currentRoute'
  },

  methods: {

    currentRoute() {
      this.$nextTick(() => {
        if((this.$route.name) === "Login") {
          document.body.style.backgroundColor = "#63b746";
        } else {
          document.body.style.backgroundColor = "white";
        }
      });
    }

  }
}
</script>