<template>
    <div class="container">
        <div class="row">
          <div class="offset-4 col-4">
            <div class="db-login-title d-flex flex-column">
              <img class="d-inline mx-auto" src="../assets/img_logo_1.png">
              <h2 class="d-inline mx-auto">Parelroutes Tholen</h2>
            </div>
          </div>
        </div>

      <div class="login-row row">
        <div class="col-md-6 col-12 offset-md-3 d-flex justify-content-center">
          <div class="db-login-box">
            <div class="db-login-content d-flex flex-column">
              <h2 class="d-inline mx-auto">Dashboard</h2>
                <form @submit.prevent="login">
                  <div class="db-login-form">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="offset-1 col-4 db-login-input d-flex justify-content-center align-items-center">
                            <p class="ms-auto">Gebruikersnaam</p>
                          </div>
                          <div class="col-6 col-md-5 db-login-input d-flex justify-content-center">
                            <input type="text" v-model="gebruikersnaam" class="db-input d-inline-block">
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="offset-1 col-4 db-login-input d-flex justify-content-center align-items-center">
                            <p class="ms-auto">Wachtwoord</p>
                          </div>
                          <div class="col-6 col-md-5 db-login-input d-flex justify-content-center">
                            <input type="password" v-model="password" class="db-input d-inline-block">
                          </div>
                        </div>
                      </div>
                      <p class="db-login-feedback m-1" style="text-align: center; color: red">{{ loginFeedback }}</p>
                      <div class="offset-md-4 col-md-4 offset-4 col-4 m d-flex mb-3 mb-lg-0 justify-content-center">
                        <button type="submit" class="db-login-btn d-inline-block">Inloggen</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      <p class="db-forgotpassword d-flex justify-content-center">Wachtwoord vergeten? Neem <a class="db-underline-link" href="https://www.google.nl">contact</a> op met uw beheerder</p>
    </div>
</template>

<script>

export default {
  name: "Login",

  data() {
    return {
      gebruikersnaam: "",
      password: "",
      loginFeedback: "",
    };
  },

  methods: {

    async login() {

      if (this.gebruikersnaam.length > 0 && this.password.length > 0) {

        this.loginFeedback = ""

      let loginArr =
        {
          Naam: this.gebruikersnaam,
          Wachtwoord: this.password,
        }


      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(loginArr)
      };

      const response = await fetch("https://nodejs.imaginecreativeagency.nl/parelroute-dashboard/user/getUser.php", requestOptions);
      const data = await response.json();

      if (data['success'] === true && data['response'] != null) {
        this.loginFeedback = "Welkom..."
        await this.sleep(1500);
        localStorage.setItem('token', 'Yr2ZOeGEh1Vs6tXqHUwF2i0yCy5WDtHd')
        this.$router.push("/data");
      } else if (data['success'] === false) {
        this.loginFeedback = "Deze gegevens zijn onjuist."
      } else if (data['success'] === false && data['response'] != null) {
        console.log(data['response'])
        this.loginFeedback = data['response']
      }
    } else {

        if (this.gebruikersnaam === "" || this.password === "") {
          this.loginFeedback = "Niet alle velden zijn ingevuld."
        }
      }
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

  }
}

</script>
